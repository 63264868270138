import * as React from 'react'
import { January } from './January'

export function Year2021() {
  return (
    <>
      <January />
    </>
  )
}
